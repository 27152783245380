import React, { Component } from "react"
import Slider from "react-slick"
import Fleet from "../Fleet"

export class FleetList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideIndex: 0,
      updateCount: 0,
    }
  }

  render() {
    const { fleets } = this.props
    const settings = {
      adaptiveHeight: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      afterChange: () =>
        this.setState((state) => ({
          updateCount: state.updateCount + 1,
        })),
      beforeChange: (current, next) =>
        this.setState({
          slideIndex: next,
        }),
    }

    return (
      <div className="fleet section is-paddingless">
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          {fleets.map(({ node: fleet }) => (
            <img
              className=""
              key={fleet.id}
              style={{ objectFit: "cover", maxHeight: 400 }}
              src={fleet.frontmatter.cover}
            />
          ))}
        </Slider>

        <Fleet fleet={fleets[this.state.slideIndex].node.frontmatter} />
      </div>
    )
  }
}

export default FleetList
