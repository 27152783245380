/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../../layouts/PageWrapper"
import FleetList from "../../components/FleetList"
import HomeLogo from "../../components/HomeLogo"

class FleetsPage extends Component {
  render() {
    const { data } = this.props
    const { group: fleets } = data.allMarkdownRemark

    return (
      <PageWrapper>
        <Helmet>
          <title>Our Fleets - E.A. Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <HomeLogo />
                  <h1 className="title" id="title-line">
                    Our Fleets
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container ">
            <div className="columns" id="fleet_column">
              <div className="column is-11">
                <Tabs
                  forceRenderTabPanel={false}
                  defaultIndex={0}
                  onSelect={(index) => console.log(index)}
                >
                  <TabList className="react-tabs__tab-list-primary">
                    {fleets.map((fleetGroup, index) => (
                      <Tab
                        className="react-tabs__tab has-text-centered"
                        key={index}
                      >
                        {fleetGroup.fieldValue}s
                      </Tab>
                    ))}
                  </TabList>
                  {fleets.map((fleetGroup, index) => {
                    return (
                      <TabPanel key={index}>
                        <FleetList fleets={fleetGroup.edges} />
                      </TabPanel>
                    )
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </PageWrapper>
    )
  }
}

FleetsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default FleetsPage

export const fleetsPageQuery = graphql`
  query FleetsPage {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "fleet-page" } } }
    ) {
      group(field: frontmatter___type) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              cover
              templateKey
              summary {
                engine
                service_speed
                year_built
                builder
                converted_to_fois
                owner
              }
              specifications {
                call_sign
                port_of_registry
                official_no
                type
                imo_no
                class
                loa
                beam_moulded
                depth_moulded
                draft
                grt
                nrt
                dwt
              }
            }
          }
        }
      }
    }
  }
`
