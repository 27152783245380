import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase()
    })
}

const Fleet = ({ fleet }) => {
  return (
    <div className="section is-paddingless">
      <h3 className="title is-3 is-mt-4">{fleet.title}</h3>

      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>Summary</Tab>
          <Tab>Specifications</Tab>
        </TabList>

        <TabPanel forceRender>
          <div className="hero is-small is-light">
            <div className="hero-body">
              {Object.entries(fleet.summary).map(([key, value], index) => {
                if (
                  !(humanize(key).match("Converted to fois") && value == null)
                ) {
                  return (
                    <React.Fragment key={index}>
                      <p className="has-text-weight-bold">
                        {humanize(key)}:{" "}
                        <span className="has-text-weight-normal">{value}</span>
                      </p>
                    </React.Fragment>
                  )
                }
              })}
            </div>
          </div>
        </TabPanel>
        <TabPanel forceRender>
          <div className="hero is-small is-light">
            <div className="hero-body">
              {Object.entries(fleet.specifications).map(
                ([key, value], index) => (
                  <React.Fragment key={index}>
                    <p className="has-text-weight-bold">
                      {humanize(key)}:{" "}
                      <span className="has-text-weight-normal">{value}</span>
                    </p>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Fleet
